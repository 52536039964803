import React from "react";
import { useNavigate } from "react-router-dom";
import "../../assets/css/menuSidebar.css";


const SearchBar = (props) => {

    return (
        <div className="input-group" style={{'paddingRight':'20px','textAlign':'end'}}>
            <input type="username" name="username" placeholder="buscador" style={{'maxWidth':'250px'}}/>
          </div>
    );
};

export default SearchBar;

