import React from 'react';
import logo from "../assets/img/logo.png";
import "../assets/css/login.css";
import { useNavigate } from "react-router-dom";

export default function Login() {

  const login = async (url, usr, pass) => {
    try {
      const resp = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        'body': JSON.stringify({ 'usuario': usr, 'clave': pass }),
      });
      const json = await resp.json();
      if (json.conectado) {
        localStorage.setItem("auth", JSON.stringify(json));
        navigate("/Dashboard");
      } else {
        alert(json.error);
      }
      console.log(json);
    } catch (error) {
      console.log(error);
    }
  }

  const navigate = useNavigate();

  const handleSubmit = e => {
    e.preventDefault();
    if (!e.target.username.value) {
      alert("El usuario es requerido");
    } else if (!e.target.password.value) {
      alert("La contraseña es requerida");
    } else {
      login(process.env.REACT_APP_BACKEND_URL + "/loginExt.php", e.target.username.value, e.target.password.value);
    }
  };

  const handleClick = e => {
    e.preventDefault();

    alert("Goes to registration page");
  };
  return (
    <div className="center" >
      <div className="App" >
        <img src={logo} className="logo" alt="Business view - Reports" style={{ 'backgroundColor': '#091d44', 'width': '200px', 'padding': '10px', 'borderRadius': '4px', 'boxShadow': '2px 4px 6px rgb(0 0 0 / 20%)' }} />
        <form className="form" onSubmit={handleSubmit}>
          <div className="input-group">
            <label htmlFor="username">Usuario</label>
            <input type="username" name="username" placeholder="example"/>
          </div>
          <div className="input-group">
            <label htmlFor="password">Contraseña</label>
            <input type="password" name="password" />
          </div>
          <button className="button-6 primary">ENTRAR</button>
        </form>
      </div>
    </div>

  );
}