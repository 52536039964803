import React from "react";
import { useNavigate } from "react-router-dom";
import "../../assets/css/menuSidebar.css";
import logo from "../../assets/img/logo.png";

const pantallas = ["certificacion", "talleres", "ensayos"];

const MenuSidebar = (props) => {

  const navigate = useNavigate();
  const [PantallasPermisos, setPantallasPermisos] = React.useState([]);
  const [Nombre, setNombre] = React.useState("test");
  const cargarPerfil = async (url) => {
    try {
      var retrievedObject = localStorage.getItem('auth');
      const auth = JSON.parse(retrievedObject);
      setNombre(auth.nombre + " " + auth.apellidos);
      const resp = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        'body': JSON.stringify({ 'perfil': auth.id }),
      });
      const json = await resp.json();
      pantallas.map(function (element) {
        if (json.find(arreglo => arreglo['nombre'] === element)) {
        } else {
          try {
            const elemento = document.getElementById(element);
            elemento.remove();
          } catch (error) {

          }
        }
      }
      );
      document.getElementById("menu").style.visibility = "visible";
    } catch (error) {
      console.log(error);
    }
  }

  React.useEffect(() => {
    if (PantallasPermisos.length === 0) {
      try {
        cargarPerfil(process.env.REACT_APP_BACKEND_URL + "/obtenerPermisosBiblioteca.php");
      } catch (error) {

      }
    }

  }, [PantallasPermisos])

  function miFunc() {

    localStorage.clear();
    navigate("/");
  }

  return (

    <nav className="main-menu" id="menu">

      <div style={{  'textAlign':'center','paddingTop':'10px','paddingBottom':'20px' }}>
        <img src={logo} className="logo" alt="Business view - Reports" style={{ 'backgroundColor': '#091d44', 'width': '120px', 'padding': '10px', 'borderRadius': '4px', 'boxShadow': '2px 4px 6px rgb(0 0 0 / 20%)' }} />

      </div>

      <ul>

        <li id="certificacion">
          <a href="/Dashboard/Certificacion">
            <i className="fa fa-folder-open fa-2x"></i>
            <span className="nav-text">
              Certificación
            </span>
          </a>
        </li>
        <li id="talleres">
          <a href="/Dashboard/Talleres">
            <i className="fa fa-folder-open fa-2x"></i>
            <span className="nav-text">
              Talleres
            </span>
          </a>
        </li>
        <li id="ensayos">
          <a href="/Dashboard/Ensayos">
            <i className="fa fa-folder-open fa-2x"></i>
            <span className="nav-text">
              Ensayos
            </span>
          </a>
        </li>
        <li id="chas">
          <a href="/Dashboard/Chas">
            <i className="fa fa-folder-open fa-2x"></i>
            <span className="nav-text">
              CHAS
            </span>
          </a>
        </li>
        <li id="rtoe">
          <a href="/Dashboard/Rtoe">
            <i className="fa fa-folder-open fa-2x"></i>
            <span className="nav-text">
              RTOE
            </span>
          </a>
        </li>
      </ul>
      <ul className="logout">
        <li>
          <a onClick={miFunc}>
            <i className="fa fa-power-off fa-2x"></i>
            <span className="nav-text">
              Salir
            </span>
          </a>
        </li>
      </ul>

    </nav>
  );
};

export default MenuSidebar;

