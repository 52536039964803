/*eslint-disable*/
import React, { useContext, useEffect, useState } from "react";
import MenuSidebar from '../../components/sidebar/menuSidebar';
import SearchBar from "../../components/bar/searchBar";
import "../../assets/css/tab.css";
import "../../assets/css/general.css";
import Loading from "../../assets/img/Loading.gif";
import logo from "../../assets/img/logo.png";

export default function Certificacion() {

  const [Biblioteca, setBiblioteca] = React.useState([]);
  const [BibliotecaBusqueda, setBibliotecaBusqueda] = React.useState([]);
  const [Activo, setActivo] = React.useState("certificacion");
  const [Ruta, setRuta] = React.useState("");
  const [RutaArchivo, setRutaArchivo] = React.useState("");
  const [SubActivo, setSubActivo] = React.useState("");

  const cargarBiblioteca = async (url) => {
    try {
      console.log(url);
      setBibliotecaBusqueda();
      const resp = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        'body': JSON.stringify({ 'carpeta': Activo }),
      });
      const json = await resp.json();
      console.log(json);
      setBiblioteca(json);

    } catch (error) {
      console.log(error);
    }
  };

  const cargarSubBiblioteca = async (url, nombre) => {
    try {
      setBibliotecaBusqueda();
      const resp = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        'body': JSON.stringify({ 'carpeta': Activo + '/' + nombre }),
      });
      const json = await resp.json();
      setBiblioteca(json);

    } catch (error) {
      console.log(error);
    }
  };

  const cargarBusqueda = async (url, nombre) => {
    try {
      setBiblioteca();
      const resp = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        'body': JSON.stringify({ 'carpeta': Activo, 'valor': nombre }),
      });
      const json = await resp.json();
      
      document.getElementById("cargando").style.visibility = "hidden";
      setBibliotecaBusqueda(json);
      console.log(json);

    } catch (error) {
      console.log(error);
    }
  };


  React.useEffect(() => {

    if (Activo.length > 0) {
      try {
        cargarBiblioteca(process.env.REACT_APP_BACKEND_URL + "/cargar.php");
        try {
          document.getElementById("search")
            .addEventListener("keydown", function (event) {

              if (event.key === "Enter") {
                event.preventDefault();
                document.getElementById("myButton").click();
              }
            });

        } catch (error) {

        }
      } catch (error) {

      }
    }
  }, [Activo])

  function ObtenerListadoBiblioteca(props) {
    let carpetas = [];
    let archivos = [];
    if (Activo === (props.nombre)) {
      for (const property in Biblioteca) {
        if (`${Biblioteca[property].type}` === "Carpeta") {
          carpetas.push(`${Biblioteca[property].name}`);
        }
        else {
          archivos.push(`${Biblioteca[property].name}`);
        }

      }
    }
    try {
      if (props.currentTarget.id !== undefined) {
        setSubActivo(props.currentTarget.id);
        cargarSubBiblioteca(process.env.REACT_APP_BACKEND_URL + "/cargar.php", props.currentTarget.id)
        for (const property in Biblioteca) {
          {
            archivos.push(`${Biblioteca[property].name}`);
          }

        }
      } else {

      }
    } catch (error) {
    }

    return (
      <ul className="directory-list listado">
        {SubActivo !== "" ? <li  >
          <a onClick={ObtenerListadoBiblioteca} href="#"  >
            <i className="fa fa-arrow-circle-left  fa-2x" style={{ 'width': '30px', 'fontSize': '16px', 'height': '20px' }}></i>
            <span className="nav-text" >
              volver
            </span>
          </a>
        </li> : null}
        {carpetas.map(name => <li key={name}>
          <a onClick={ObtenerListadoBiblioteca} nombre={Activo + '/' + name} id={name} href={"#?namefolder=" + name}>
            <i className="fa fa-folder  fa-2x" style={{ 'width': '30px', 'fontSize': '16px', 'height': '20px' }}></i>
            <span className="nav-text" >
              {name}
            </span>
          </a>
        </li>)}
        {archivos.map(name => <li key={name} >
          <a onClick={modalTwo} id={name} name={props.nombre + '/' + SubActivo}  >
            <i className="fa fa-file fa-2x" style={{ 'width': '30px', 'fontSize': '16px', 'height': '20px' }}></i>
            <span className="nav-text">
              {name}
            </span>
          </a>
        </li>)}
      </ul>
    );

  }

  function ObtenerListadoBibliotecaBusqueda(props) {
    let archivos = [];
    if (Activo === (props.nombre)) {
      for (const property in BibliotecaBusqueda) {

        var ruta = `${BibliotecaBusqueda[property].path}`.split("\\");
        var rutaArmada = "";
        for (var i = 0; i < ruta.length - 1; i++) {
          if (rutaArmada === "" && ruta[i] === Activo) {
            rutaArmada = ruta[i];
          } else {
            if (rutaArmada !== "") {
              rutaArmada = rutaArmada + '/' + ruta[i];
            }
          }

        }
        console.log(rutaArmada);
        archivos.push({ 'name': `${BibliotecaBusqueda[property].name}`, 'path': rutaArmada });

      }
    }

    return (
      <ul className="directory-list listado">
        {archivos.map(item => <li key={item.name} >
          <a onClick={modalTwo} id={item.name} name={item.path}>
            <i className="fa fa-file fa-2x" style={{ 'width': '30px', 'fontSize': '16px', 'height': '20px' }}></i>
            <span className="nav-text">
              {item.name}
            </span>
          </a>
        </li>)}
      </ul>
    );

  }

  const cargarArchivo = async () => {
    try {
      var datos = document.getElementById("archivo").files[0];
      if (datos !== undefined) {

        const form_data = new FormData();
        form_data.append('sample_image', datos);
        var retrievedObject = localStorage.getItem('auth');
        const auth = JSON.parse(retrievedObject);
        let fullname = auth.usuario;
        const resp = await fetch(process.env.REACT_APP_BACKEND_URL + "/subir.php?folder=" + Activo + "/" + fullname, {
          method: "POST",
          body: form_data
        });
        const json = await resp.json();
        if (!alert(json)) { window.location.reload(); }
      }
      else {
        alert("Debe seleccionar un archivo");
      }



    } catch (error) {
      console.log(error);
    }
  };

  const modal = async () => {
    document.getElementById("modalOne").style.display = "block";

  };


  const modalTwo = async (e) => {

    copiarArchivo(process.env.REACT_APP_BACKEND_URL + "/copiar.php", e.target.parentElement.id, e.target.parentElement.name);

  };

  const DescargarArchivo = async (e) => {
    window.location.href = RutaArchivo;

  };

  const copiarArchivo = async (url, nombre, ruta) => {
    try {
      var carpeta = ruta.split("/");
      var rutaArmadaFinal = "";
      if(carpeta[1] !== "" && carpeta.length > 1){
        rutaArmadaFinal = Activo + "/" + carpeta[1];
      }
      else{
        rutaArmadaFinal = Activo;  
      }
      const resp = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        'body': JSON.stringify({ 'carpeta': rutaArmadaFinal, 'valor': nombre }),
      });
      const json = await resp.json();
      if (json === "1") {
        
        setRutaArchivo(process.env.REACT_APP_BACKEND_URL + "/descarga.php?folder=" + rutaArmadaFinal + "&filename=" + nombre);
        setRuta("https://docs.google.com/viewerng/viewer?url=https://intranet.aita.org.ar/view/" + nombre + "&embedded=true");
        document.getElementById("modalTwo").style.display = "block";
      }
      else {
        console.log(json);
      }

    } catch (error) {
      console.log(error);
    }
  };

  function ObtenerIframe(props) {

    return (
      <iframe style={{ 'width': '100%', 'height': '600px' }} src={props.ruta} frameborder="0">
      </iframe>
    );

  }

  const close = async () => {
    document.getElementById("modalOne").style.display = "none";
    document.getElementById("modalTwo").style.display = "none";
    setRuta("");
  };
  const prueba = async () => {
    var datos = document.getElementById("archivo").files[0];

    var puntero = document.getElementsByClassName("file-upload-wrapper");
    puntero[0].removeAttribute('data-text');
    puntero[0].setAttribute('data-text', datos.name);

  };


  const handleClick = (e) => {
    e.preventDefault();
    var search = document.getElementById("search");
    if (search.value !== "") {
      
      document.getElementById("cargando").style.visibility = "visible";
      cargarBusqueda(process.env.REACT_APP_BACKEND_URL + "/buscar.php", search.value);
    }
    else {
      cargarBiblioteca(process.env.REACT_APP_BACKEND_URL + "/cargar.php");
    }


  };

  return (
    <>

      <MenuSidebar></MenuSidebar>

      
      <div style={{ 'width': '100%', 'textAlign': '-webkit-right', 'minHeight': '-webkit-fill-available', 'paddingTop': '30px' }}>
        <div style={{ 'width': '83%', 'textAlign': 'initial' }}>
          
          <div className="input-group-search" style={{ 'paddingRight': '0px', 'textAlign': 'center' }}>
            <input type="search" name="search" placeholder="buscador" id="search" style={{ 'maxWidth': '300px' }} />
            <button id="myButton" onClick={handleClick} className="confirm" ><i class="fa fa-search"></i></button>
          </div>
          <div>
            <button className="button-7" role="button" style={{ 'float': 'right', 'marginRight': '20px', 'marginTop': '10px' }} onClick={modal}>
              <i className="fa fa-upload fa-2x" style={{ 'width': '10px', 'height': '10px', 'fontSize': '14px', 'display': 'initial' }}></i>
              <span style={{ 'fontFamily': 'Poppins', 'fontSize': '12px', 'display': 'initial' }}> Subir Archivo</span>
            </button>

          </div>
          <div className="tabset">

            <input type="radio" name="tabset" id="certificacion" aria-controls="certificacion" defaultChecked />
            <label htmlFor="certificacion" id="certificacion">Certificaciones</label>

            <div className="tab-panels">
              <section id="certificacion" className="tab-panel">
                <div style={{'textAlign':'center'}}>
                  <img src={Loading} id="cargando" style={{'width':'40px','visibility':'hidden'}} />
                </div>
                <div className="box">
                  <ObtenerListadoBiblioteca nombre="certificacion" />
                  <ObtenerListadoBibliotecaBusqueda nombre="certificacion" />

                </div>
              </section>

            </div>
        
          </div>
          <div id="modalOne" className="modal">
            <div className="modal-content">
              <div className="contact-form">
                <a className="close" onClick={close}>&times;</a>
                <form id="frmNuevoArchivo" name="frmNuevoArchivo" className="exform" encType="multipart/form-data">
                  <div className="wrappermodal">
                    <div className="file-upload-wrapper one" data-text="...">
                      <input id="archivo" name="archivo" type="file" className="file-upload-field" onChange={prueba} />
                    </div>
                    <button type="button" id="btnNuevoArchivo" name="btnNuevoArchivo" onClick={cargarArchivo} className="button-7 two" style={{ 'marginRight': '30px' }}>Subir Archivo</button>
                  </div>

                </form>
              </div>
            </div>
          </div>
          <div id="modalTwo" className="modalTwo">
            <div className="modalTwo-content">
              <div className="contact-form">
                <a className="close" onClick={close}>&times;</a>
                <div class="buttonDownload" style={{ 'marginLeft': '40%', 'marginBottom': '5px' }} onClick={DescargarArchivo}>
                  Descargar Archivo
                  <span>
                    <span></span>
                  </span>
                </div>
                <ObtenerIframe ruta={Ruta} />
              </div>
            </div>
          </div>
        </div>
      </div>

    </>

  );
}
