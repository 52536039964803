import './App.css';
import { BrowserRouter, Routes, Route, } from 'react-router-dom';
import Login from './views/Login.js';

import Index from './views/dashboard/Index';
import Certificacion from './views/dashboard/Certificacion';
import Talleres from './views/dashboard/Talleres';
import Ensayos from './views/dashboard/Ensayos';
import Chas from './views/dashboard/Chas';
import Rtoe from './views/dashboard/Rtoe';
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path='/Dashboard'element={<Index />} />
        <Route path='/Dashboard/Certificacion'element={<Certificacion />} />
        <Route path='/Dashboard/Talleres'element={<Talleres />} />
        <Route path='/Dashboard/Ensayos'element={<Ensayos />} />
        <Route path='/Dashboard/Chas'element={<Chas />} />
        <Route path='/Dashboard/Rtoe'element={<Rtoe />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
